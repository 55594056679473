<template>
  <Card title="修改菜单">
    <div class="form">
      <a-form
        :form="form"
        @submit="handleSubmit"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        :colon="false"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="名称">
              <a-input
                v-decorator="[
                  'name',
                  {
                    initialValue: detail.name,
                    rules: [{ required: true, message: '请输入' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="路径">
              <a-input
                v-decorator="[
                  'path',
                  {
                    initialValue: detail.path,
                    rules: [{ required: true, message: '请输入' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="上级菜单">
              <a-cascader
                placeholder
                changeOnSelect
                v-decorator="['pid']"
                :options="menuList"
                :fieldNames="{
                  label: 'name',
                  value: 'id',
                  children: 'children',
                }"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="权限">
              <a-select
                show-search
                :filter-option="$selectFilterOption"
                v-decorator="[
                  'permission',
                  {
                    initialValue: detail.permission,

                    rules: [{ required: true, message: '请选择' }],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in permissionList"
                  :key="item.code"
                  :value="item.code"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="类型">
              <a-radio-group
                v-decorator="[
                  'type',
                  {
                    initialValue: detail.type,
                    rules: [{ required: true, message: '请选择' }],
                  },
                ]"
              >
                <a-radio
                  v-for="item in menuTypeList"
                  :key="item.value"
                  :value="item.value"
                >{{ item.name }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="状态">
              <a-radio-group
                v-decorator="[
                  'status',
                  {
                    initialValue: String(detail.status),
                    rules: [{ required: true, message: '请选择' }],
                  },
                ]"
              >
                <a-radio
                  v-for="item in statusList"
                  :key="item.value"
                  :value="item.value"
                >{{ item.name }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="排序">
              <a-input-number
                style="width: 100%"
                v-decorator="[
                  'sort',
                  {
                    initialValue: detail.sort,
                    rules: [{ required: true, message: '请输入' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="平台">
              <a-radio-group
                v-decorator="[
                  'platform',
                  {
                    initialValue: detail.platform,
                    rules: [{ required: true, message: '请选择' }],
                  },
                ]"
              >
                <a-radio
                  v-for="item in [
                    { name: 'PC', value: 'pc' },
                    { name: 'App', value: 'app' },
                  ]"
                  :key="item.value"
                  :value="item.value"
                >{{ item.name }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="描述" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
              <a-textarea
                :auto-size="{ minRows: 3 }"
                v-decorator="[
                  'remarks',
                  {
                    initialValue: detail.remarks,
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button @click="cancel">关闭</a-button>
            <a-button htmlType="submit" type="primary">保存</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </Card>
</template>

<script>
import { fetchList as fetchPermissionList } from "@/api/setting/permission";
import { fetchList as fetchMenuList, edit } from "@/api/setting/menu";
import { mapGetters } from "vuex";
import { buildMenuTree, findPID } from "./tools";

export default {
  data() {
    return {
      form: this.$form.createForm(this),
      permissionList: [],
      menuList: [],

      detail: {},

      pureMenuList: [], // 菜单原始数据
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    statusList() {
      return this.findDataDict("status");
    },
    menuTypeList() {
      return this.findDataDict("menuType");
    },
  },

  mounted() {
    fetchPermissionList({
      pageNum: 1,
      pageSize: 1000,
    }).then((res) => {
      if (Array.isArray(res.list)) {
        this.permissionList = Object.freeze(res.list);
      }
    });

    fetchMenuList().then((res) => {
      if (Array.isArray(res)) {
        this.menuList = Object.freeze(buildMenuTree(res, "0"));

        const str = window.localStorage.getItem("menu-detail");
        if (str) {
          this.detail = JSON.parse(str);

          const pureMenuList = res;

          pureMenuList.unshift({
            id: "0",
            name: "根菜单",
            pid: "-1",
          });

          this.pureMenuList = pureMenuList;
          this.menuList = Object.freeze(buildMenuTree(this.pureMenuList, "-1"));

          if (this.detail) {
            // 找父节点的id
            let selected = [];
            if (this.detail.pid !== "0") {
              selected = findPID(this.pureMenuList, this.detail.pid);
            } else {
              selected = ["0"];
            }
            console.log("this.detail", this.detail, selected);

            this.$nextTick(() => {
              this.form.setFieldsValue({
                pid: selected,
              });
            });
          }
        }
      }
    });
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          edit({
            ...this.detail,
            ...values,
            pid:
              values.pid && values.pid.length > 0
                ? values.pid[values.pid.length - 1]
                : "0",
          }).then(() => {
            this.cancel();
          });
        }
      });
    },

    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>
